export default {
	name: "ListaTema",
	data() {
		return {
			arCategorias: [],
			blLoading: true,
			obForm: {
				idAssunto: null
			},
			obFormPesquisa: {
				idAssunto: null
			}
		};
	},
	created() {
		this.listar();
	},
	methods: {
		listar() {
			this.$root.$api.get("categoria/listar").then(response => {
				this.arCategorias = response.retorno;
				this.blLoading = false;
			});
		},
		filtrar() {
			this.obFormPesquisa.idAssunto = this.obForm.idAssunto;
		},
	},
	computed: {
		listaTemas() {
			if (!this.obFormPesquisa.idAssunto)
				return this.arCategorias;

			return this.arCategorias.filter(obTema => {
				return obTema.id_assunto == this.obFormPesquisa.idAssunto
			})
		}
	},
};